import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import Moment from 'moment';

import { Colors } from 'constants/Clementine';

import { isMobile } from 'utils/Browser';

class AmbestInfo extends React.Component {
  static propTypes = {
    details: PropTypes.object
  };

  render() {
    const styles = this.styles();
    const ambest_record = this.props.details || {};
    const info = [
      {
        label: 'A.M. Best Rating:',
        value: ambest_record.financial_strength_rating_current
      },
      {
        label: 'Financial Size Category:',
        value: ambest_record.financial_strength_category
      },
      {
        label: 'Address:',
        value: `${ambest_record.street_address}, ${ambest_record.city}, ${ambest_record.state}, ${ambest_record.zip_code}`
      },
      {
        label: 'Phone:',
        value: ambest_record.phone || 'N/A'
      }
    ];
    const balance_sheet = [
      {
        label: 'Bonds',
        value: Numeral(ambest_record.bonds / 100).format('0.0%')
      },
      {
        label: 'Mort. Loans & Pref. Stock',
        value: Numeral(ambest_record.mortgage_loans_and_real_estate_percent / 100).format('0.0%')
      },
      {
        label: 'Com & Pref. Stock',
        value: Numeral(ambest_record.common_and_preferred_stock_percent / 100).format('0.0%')
      },
      {
        label: 'All Other Assets',
        value: Numeral(ambest_record.other_assets_percent / 100).format('0.0%')
      },
      {
        label: 'Total Assets',
        value: Numeral(ambest_record.total_assets).format('$0,0')
      },
      {
        label: 'Life Reserves',
        value: Numeral(ambest_record.life_reserves_percent / 100).format('0.0%')
      },
      {
        label: 'Health Reserves',
        value: Numeral(ambest_record.health_reserves / 100).format('0.0%')
      },
      {
        label: 'Ann Res. & Dep. Liabilities',
        value: Numeral(ambest_record.annuity_reserves_and_deposit_liabilities / 100).format('0.0%')
      },
      {
        label: 'All Other Liabilities',
        value: Numeral(ambest_record.other_liabilities / 100).format('0.0%')
      },
      {
        label: 'Capital & Surplus',
        value: Numeral(ambest_record.capital_and_surplus).format('$0,0')
      }
    ];
    const operations = [
      {
        label: 'Direct Premiums Written',
        value: Numeral(ambest_record.direct_premiums_written).format('$0,0')
      },
      {
        label: 'Net Premiums Written & Deposits',
        value: Numeral(ambest_record.net_premiums_written_and_deposits).format('$0,0')
      },
      {
        label: 'Operating Cash Flows',
        value: Numeral(ambest_record.operating_cash_flow).format('$0,0')
      },
      {
        label: 'NOG Before Taxes',
        value: Numeral(ambest_record.nog_before_taxes).format('$0,0')
      },
      {
        label: 'NOG After Taxes',
        value: Numeral(ambest_record.nog_after_taxes).format('$0,0')
      },
      {
        label: 'Net Income',
        value: Numeral(ambest_record.net_income).format('$0,0')
      },
      {
        label: 'Unrealized Capital Gains',
        value: Numeral(ambest_record.unrealized_capital_gains).format('$0,0')
      }
    ];

    return (
      <div>
        <div style={styles.info}>
          {info.map((info_item, i) => {
            return (
              <div key={i} style={styles.info_item}>
                <label style={styles.info_label}>{info_item.label}</label>
                {info_item.value}
              </div>
            );
          })}
        </div>
        <div style={styles.tables}>
          <table style={styles.table}>
            <tbody>
              {balance_sheet.map((row, i) => {
                return (
                  <tr key={i}>
                    <td style={styles.table_label}>{row.label}</td>
                    <td>{row.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <table style={styles.table}>
            <tbody>
              {operations.map((row, i) => {
                return (
                  <tr key={i}>
                    <td style={styles.table_label}>{row.label}</td>
                    <td>{row.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <p style={styles.disclaimer}>
          Rating as of {Moment(ambest_record.updated_at).format('MM-DD-YYYY')} For the latest rating, access{' '}
          <a href='http://www.ambest.com' style={{ color: Colors.BLUE.hex }} target='_blank'>
            www.ambest.com
          </a>
          . A description of A.M. Best Ratings can be found on the A.M. Best website at{' '}
          <a href='http://www.ambest.com/ratings/guide.asp' style={{ color: Colors.BLUE.hex }} target='_blank'>
            http://www.ambest.com/ratings/guide.asp
          </a>
        </p>
      </div>
    );
  }

  styles = () => {
    return {
      info: {
        marginBottom: 20
      },
      info_item: {
        marginBottom: 5
      },
      info_label: {
        fontWeight: 600,
        marginRight: 5
      },
      tables: {
        display: isMobile ? 'block' : 'flex',
        justifyContent: 'space-between'
      },
      table: {
        width: isMobile ? '100%' : '49%',
        marginBottom: isMobile ? 15 : 'default',
        fontSize: 14
      },
      table_label: {
        backgroundColor: '#f9f9f9',
        textAlign: 'right',
        fontWeight: 600
      },
      disclaimer: {
        width: isMobile ? '95%' : '100%',
        marginTop: 20,
        fontStyle: 'italic'
      }
    };
  };
}

export default AmbestInfo;
