import React from 'react';
import PropTypes from 'prop-types';

import { FontSizes } from 'constants/Clementine';

class ZeroState extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    message: PropTypes.node
  };

  render() {
    const { icon, message } = this.props;
    const styles = this.styles();

    return (
      <div style={styles.component}>
        <i className={`mdi ${icon || 'mdi-magnify'}`} style={styles.icon} />
        <div>{message}</div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        textAlign: 'center',
        padding: '60px 0 100px',
        width: '100%',
        fontSize: FontSizes.MEDIUM,
        color: '#bbb',
        fontStyle: 'italic'
      },
      icon: {
        display: 'block',
        fontSize: 60,
        marginBottom: 6,
        color: '#e5e5e5'
      }
    };
  };
}

export default ZeroState;
