import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import { FontWeights, FontSizes } from 'constants/Clementine';

const Button = ({ children, color, disabled, fontColor, icon, id, inline, onClick, outline, small, style }) => {
  const context = React.useContext(ThemeContext);
  const visible_color = color || context.color;
  const styles = Object.assign(
    {},
    {
      height: small ? 30 : 40,
      lineHeight: small ? '28px' : '38px',
      textAlign: 'center',
      borderRadius: 4,
      border: `1px solid ${visible_color}`,
      fontSize: small ? FontSizes.REGULAR : FontSizes.MEDIUM,
      fontWeight: FontWeights.MEDIUM,
      color: outline ? visible_color : fontColor || '#fff',
      background: outline ? 'transparent' : visible_color,
      cursor: disabled ? 'default' : 'pointer',
      padding: small ? '0px 10px' : '0px 30px',
      display: inline ? 'inline-block' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: disabled ? 0.5 : 1,
      whiteSpace: 'nowrap'
    },
    style
  );

  if (icon && !children) {
    styles.width = small ? 30 : 40;
    styles.fontSize = small ? 18 : 24;
    styles.fontWeight = 500;
    styles.padding = 0;
  }

  return (
    <div id={id} onClick={disabled ? null : onClick} style={styles}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', lineHeight: small ? '30px' : '40px' }}>
        {icon ? (
          <i
            className={`mdi mdi-${icon}`}
            style={{
              marginRight: children ? 8 : 0,
              fontSize: small && children ? 18 : 20
            }}
          />
        ) : null}
        {children}
      </div>
    </div>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fontColor: PropTypes.string,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  small: PropTypes.bool
};

export default Button;
