import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Numeral from 'numeral';

import { isMobile } from 'utils/Browser';

import { FontSizes } from 'constants/Clementine';

import { SA_BASE_URL } from 'config/App';

class QuoteSimple extends React.Component {
  static propTypes = {
    quote: PropTypes.object.isRequired
  };

  render() {
    const styles = this.styles();
    const quote = this.props.quote;

    return (
      <div style={styles.component}>
        <div style={styles.content}>
          <div
            style={Object.assign(
              {},
              styles.company,
              quote.carrier_id
                ? {
                    backgroundImage: `url(${SA_BASE_URL}/ixn-data/logos/carrier_` + quote.carrier_id + '_medium.png)'
                  }
                : null
            )}
          >
            {!quote.carrier_id ? quote.carrier_name : null}
          </div>
          <div style={styles.product}>
            <div style={styles.product_name}>{quote.product_name}</div>({quote.carrier_health_category} {quote.table_rate_letter ? `(Table ${quote.table_rate_letter})` : null})
          </div>
          <div style={styles.premium}>
            {['monthly', 'quarterly', 'semi_annual', 'annual'].map(payment_mode => {
              return (
                <div key={payment_mode} style={styles.premium_total}>
                  <span style={styles.premium_interval}>{payment_mode.replace('_', ' ')}:</span>{' '}
                  <span style={styles.premium_amount}>{Numeral(quote[`premium_${payment_mode}`]).format('$0,0.00')}</span>
                </div>
              );
            })}
          </div>
          {!isMobile ? (
            <div style={styles.rating}>
              {quote.am_best_rating}
              <div style={styles.rating_date}>{quote.am_best_date ? Moment(quote.am_best_date, 'YYYY-MM-DD').format('MMM Do, YYYY') : ''}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: Object.assign(
        {},
        {
          backgroundColor: '#fff',
          border: '1px solid #e5e5e5',
          borderRadius: 5,
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative'
        },
        this.props.style
      ),
      content: {
        padding: 20,
        display: isMobile ? 'block' : 'flex',
        textAlign: isMobile ? 'center' : 'inherit',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      company: {
        width: isMobile ? '100%' : '20%',
        height: 40,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        marginBottom: isMobile ? 20 : 0,
        textAlign: 'center',
        fontWeight: 600
      },
      product_name: {
        fontSize: FontSizes.LARGE,
        fontWeight: 600,
        marginBottom: 5
      },
      premium: {
        width: isMobile ? '100%' : '30%',
        textAlign: isMobile ? 'center' : 'right'
      },
      premium_total: {
        padding: '5px 0',
        lineHeight: '1em'
      },
      premium_amount: {
        fontWeight: 600,
        fontSize: FontSizes.LARGE
      },
      premium_interval: {
        textTransform: 'capitalize'
      },
      product: {
        width: isMobile ? '100%' : '25%',
        lineHeight: '1.3em',
        marginBottom: isMobile ? 15 : 0
      },
      rating: {
        textAlign: 'center',
        fontSize: FontSizes.XLARGE,
        width: '10%'
      },
      rating_date: {
        fontSize: FontSizes.SMALL,
        marginTop: 5
      }
    };
  };
}

export default QuoteSimple;
