import React from 'react';

import 'css/Loader.css';

const Loader = () => {
  return (
    <div className='loader'>
      <div className='dot dot1' />
      <div className='dot dot2' />
      <div className='dot dot3' />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
